import Swiper from "swiper";
import SwiperCore, {
	Navigation,
	Pagination,
	EffectFade,
	Autoplay,
} from "swiper/core";
import "swiper/swiper-bundle.min.css";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

const swiper = new Swiper(".News-slider", {
	effect: "fade",
	fadeEffect: {
		crossFade: true,
	},
	loop: true,
	autoplay: true,
	speed: 500,
	autoplaySpeed: 3000,
	allowTouchMove: false,
	pagination: {
		el: ".News__navigationDots",
		type: "bullets",
		clickable: true,
	},
	navigation: {
		nextEl: ".News__arrow--right",
		prevEl: ".News__arrow--left",
	},
});
