import axios from "axios";

const URL = "https://scalaplastics.pl/";

export default class SearchForm {
  constructor(container) {
    this.container = container;
    this.form = this.container.querySelector("form");
    this.searchTerm = null;
    this.timeout = null;
    this.openBtn = document.querySelector(".open-seach-form-btn");
    this.closeBtn = document.querySelector(".close-search-form-popup");
    this.formPopup = document.querySelector(".search-form-popup");
    this.resultsContainer = this.formPopup.querySelector(
      ".search-results-wrapper .wrapper"
    );
    this.pageContainer = document.getElementById("page");
    this.loader = this.formPopup.querySelector(".s-loader");
    this.categoriesNotFound = false;
    this.productsNotFound = false;
    this.init();
  }

  init() {
    this.openBtn.addEventListener("click", (e) => {
      e.preventDefault();
      this.formPopup.classList.add("open");
      this.pageContainer.classList.add("darken");
      setTimeout(() => {
        document.querySelector(".s-input").focus();
      }, 100);
    });

    this.closeBtn.addEventListener("click", (e) => {
      e.preventDefault();
      this.formPopup.classList.remove("open");
      this.pageContainer.classList.remove("darken");
    });

    this.form.addEventListener("input", (e) => {
      this.searchTerm = e.target.value;
      clearTimeout(this.timeout);
      if (this.searchTerm.length > 2) {
        this.timeout = setTimeout(() => {
          this.resultsContainer.innerHTML = "";
          this.showLoader();
          this.getCategoriesResults();
          this.getProductsResults();

          setTimeout(() => {
            if (this.categoriesNotFound && this.productsNotFound) {
              this.nothingFoundCheck();
            }
          }, 1000);
        }, 1000);
      }
    });
  }

  getCategoriesResults() {
    const options = {
      method: "GET",
      url: `${URL}/wp-json/kreacja/v1/category/${this.searchTerm}`,
    };

    axios
      .request(options)
      .then((response) => response.data)
      .then((data) => {
        const terms = Object.entries(data.data);

        if (data.success === true && terms.length > 0) {
          this.buildList("kategorie", Object.entries(data.data));
          this.categoriesNotFound = false;
        } else if (terms.length === 0) {
          this.categoriesNotFound = true;
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  getProductsResults() {
    const options = {
      method: "GET",
      url: `${URL}/wp-json/products/v1/search?key=${this.searchTerm}`,
    };

    axios
      .request(options)
      .then((response) => {
        if (response.data.length > 0) {
          this.buildList("produkty", response.data);
          this.productsNotFound = false;
        } else {
          this.productsNotFound = true;
        }
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  buildList(type, data) {
    let sectionHeading = "";
    if (type === "kategorie") {
      sectionHeading = "Kategorie";
    } else if (type === "produkty") {
      sectionHeading = "Produkty";
    }

    const container = document.createElement("div");
    const heading = document.createElement("h4");
    container.classList.add("search-results");
    container.classList.add(type);
    heading.innerText = sectionHeading;
    container.appendChild(heading);

    data.map((item) => {
      const itemEl = document.createElement("a");

      if (type === "kategorie") {
        itemEl.innerHTML = `<span style='margin-right:35px;' class="icon-Grid-cards"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>`;
        itemEl.innerHTML += item[0];
        itemEl.href = item[1];
      }

      if (type === "produkty") {
        itemEl.href = item.permalink;
        itemEl.innerHTML = `<img src='${item.images.shop_thumbnail}' />`;
        itemEl.innerHTML += item.name;
        itemEl.innerHTML += item.price;
      }

      container.appendChild(itemEl);
    });

    this.resultsContainer.appendChild(container);
    this.hideLoader();
  }

  nothingFoundCheck() {
    this.hideLoader();
    const container = document.createElement("div");
    const heading = document.createElement("h4");
    container.classList.add("search-results");
    heading.innerText = "Brak wyników dla wpisanej frazy";
    container.appendChild(heading);
    this.resultsContainer.appendChild(container);
  }

  showLoader() {
    this.loader.classList.add("show");
  }

  hideLoader() {
    this.loader.classList.remove("show");
  }
}
