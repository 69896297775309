/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */

export const toggleMenu = () => {
  const hamburgerBtn = document.querySelector(".Hamburger");
  const menu = document.querySelector(".MainMenu");

  hamburgerBtn.addEventListener("click", () => {
    hamburgerBtn.classList.toggle("Hamburger--open");
    menu.classList.toggle("active");
  });
};

export const closeMenu = () => {};

export const addSticky = () => {
  let toggle = document.getElementById("toggle"),
    header = document.getElementById("masthead");

  toggle &&
    toggle.addEventListener("click", () => {
      header.classList.add("sticky");
    });
};

const subMenuElement = document.querySelector(".products-menu .sub-menu");
const subMenuElement2 = document.querySelector(".contact__chevron .sub-menu");
const newElement = document.createElement("img");
newElement.src =
  "https://scalaplastics.pl/wp-content/uploads/2023/04/dropdown__grey.png";

const newElement2 = document.createElement("img");
newElement2.src =
  "https://scalaplastics.pl/wp-content/uploads/2023/04/dropdown__grey.png";

newElement.classList.add("chevron");
newElement2.classList.add("chevron");

if (subMenuElement && subMenuElement2) {
  subMenuElement.insertAdjacentElement("afterend", newElement);
  subMenuElement2.insertAdjacentElement("afterend", newElement2);

  newElement.addEventListener("click", function() {
    subMenuElement.classList.toggle("active");
    newElement.classList.toggle("active");
  });

  newElement2.addEventListener("click", function() {
    subMenuElement2.classList.toggle("active");
    newElement2.classList.toggle("active");
  });
}
